import React, { FC } from 'react'
import { Button } from '@mui/material'

const generateLink = (title: string, buffer: any) => {
  return (
    <Button
      onClick={() => {
        // const link = document.createElement('a')
        // link.target = '_blank'
        // link.href = `data:application/pdf;base64,${buffer}`
        // link.click()

        var byteCharacters = atob(buffer)
        var byteNumbers = new Array(byteCharacters.length)
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        var byteArray = new Uint8Array(byteNumbers)
        var file = new window.Blob([byteArray], { type: 'application/pdf' })

        var reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = function () {
          const base64data = reader.result
          // For IE:
          if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
            ;(window.navigator as any).msSaveOrOpenBlob(base64data)
          } // For webkit
          else {
            var objectUrl = URL.createObjectURL(file)
            window.open(objectUrl)
          }
        }

        // var fileURL = URL.createObjectURL(file)
        // window.open(fileURL)
      }}
    >
      {title} downloaden
    </Button>
  )
}

export const TestListing: FC<{
  result_de: string | null
  result_en: string | null
  result_japan: string | null
}> = ({ result_de, result_en, result_japan }) => (
  <>
    {result_de && generateLink('Deutsches Ergebnis', result_de)}
    {result_en && generateLink('Englisches Ergebnis', result_en)}
    {result_japan && generateLink('Japan Zertifikat', result_japan)}
  </>
)

//   function save(filename, data) {

// }
